import { useState, useEffect, useRef, FC } from 'react';
import Image from 'next/legacy/image';
import styles from '@styles/Homepage/enjoy.module.scss';
import { Button, Modal } from 'antd';
import { PlayArrowIcon } from '../CustomIcons';
import { ComponentTbaLandingSectionsImagesGridWithVideo } from 'services/graphql/cms';

interface Props {
  imagesGridWithVideo: ComponentTbaLandingSectionsImagesGridWithVideo;
}

const Enjoy: FC<Props> = ({ imagesGridWithVideo }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(false);

  const showModal = () => {
    setModalTrigger(true);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const iframe: any = useRef();

  useEffect(() => {
    if (!isModalVisible && modalTrigger) {
      iframe.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    } else if (isModalVisible && modalTrigger) {
      iframe.current.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }

    return () => { };
  }, [isModalVisible, modalTrigger]);

  return (
    <>
      <section className={styles.container}>
        <div className={styles.container__content}>
          <h2 className={styles.content__title} dangerouslySetInnerHTML={{ __html: imagesGridWithVideo?.title }} />
          <div
            className={styles.content__text}
            dangerouslySetInnerHTML={{ __html: imagesGridWithVideo?.description }}
          />

          <div className={styles.content__more}>
            <Button className={styles.content__more__button} onClick={showModal}>
              <PlayArrowIcon />
            </Button>

            <div className={styles.content__more__text}>
              <p className={styles.content__more__text___simple}>See how it works.</p>
              <Button className={styles.content__more__text___bold} onClick={showModal}>
                Watch video
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.container__grid}>
          {imagesGridWithVideo?.gridImages?.data?.map((item) => (
            <div className={styles.grid__item} key={item.id}>
              <Image
                src={item?.attributes?.url}
                alt={item?.attributes?.alternativeText}
                className={styles.grid__item__image}
                width={270}
                height={288}
              />
            </div>
          ))}
        </div>
      </section>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className="enjoy__modal"
      >
        <iframe
          title="Youtube"
          src={imagesGridWithVideo?.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          ref={iframe}
        />
      </Modal>
    </>
  );
};

export default Enjoy;
